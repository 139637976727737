import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule }    from '@angular/common/http';
import {FormService} from './form.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './form/form.component';
import { ThankyouFormComponent } from './thankyou-form/thankyou-form.component';
import { ExistingEmailComponent } from './existing-email/existing-email.component';
import { AlreadyEarnedPointsComponent } from './already-earned-points/already-earned-points.component';
import { InactiveLinkComponent } from './inactive-link/inactive-link.component';
import { NotFoundComponent } from './not-found/not-found.component';



@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    ThankyouFormComponent,
    ExistingEmailComponent,
    AlreadyEarnedPointsComponent,
    InactiveLinkComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,  
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [FormService],
  bootstrap: [AppComponent]
})
export class AppModule { }

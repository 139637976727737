import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { GenericResponse } from './responses/dtos/generic.response';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  uri = 'https://k4ztigyu39.execute-api.eu-west-1.amazonaws.com/prod/GDBEmailCollectorService?qcCode=${personQCCode}';
  memberSearchUri = 'https://k4ztigyu39.execute-api.eu-west-1.amazonaws.com/prod/GDBEmailCollectorService';
  updateMemberUri = 'https://k4ztigyu39.execute-api.eu-west-1.amazonaws.com/prod/GDBEmailCollectorService?personQCCode=${personQCCode}&memberQCCode=${memberQCCode}';

  constructor(private http: HttpClient) {
  }

  memberSearch(memberQCCode: string): Observable<any> {
    const obj = {
      "criteriaList": [
        {
          "criteriaType": "LOYALTY_MEMBERSHIP_QCCODE",
          "code": memberQCCode
        }
      ],
      "pagination": {
        "page": 1,
        "pageSize": 10
      },
      "sorting": {
        "sortingField": "ID",
        "sortingOrder": "ASC"
      }
    }

    console.log('Request object is: ', obj);
    return this.http.post<GenericResponse<any>>(`${this.memberSearchUri}`, obj).pipe(
      catchError(this.handleError)
    );
  }

  markMember(personQCCode: string, memberQCCode: string, attributeName: string, attributeValue: string): Observable<any> {
    const obj = {
      "attributes": [
        {
          "attributeName": attributeName,
          "attributeValue": attributeValue,
          "dataType": "BOOLEAN"
        }
      ]
    }

    console.log('Request object is: ', obj);

    const baseURL = this.updateMemberUri.replace('${personQCCode}', personQCCode).replace('${memberQCCode}', memberQCCode);
    console.log(baseURL);
    return this.http.put<GenericResponse<any>>(`${this.updateMemberUri.replace('${personQCCode}', personQCCode).replace('${memberQCCode}', memberQCCode)}`, obj).pipe(
      catchError(this.handleError)
    );
  }


  updateEmail(email: string, personQCCode: string): Observable<any> {
    const obj = {
      "emails": {
        "modifications": [],
        "additions": [
          {
            "emailAddress": email,
            "isPrimary": true
          }
        ]
      }
    };

    console.log('Request object is: ', obj);

    const baseURL = this.uri.replace('${personQCCode}', personQCCode);
    console.log(baseURL);
    return this.http.put<GenericResponse<any>>(`${this.uri.replace('${personQCCode}', personQCCode)}`, obj).pipe(
      catchError(this.handleError)
    );
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error}`);
    }
    return of(error);
  };
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-link',
  templateUrl: './inactive-link.component.html',
  styleUrls: ['./inactive-link.component.css']
})
export class InactiveLinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './form/form.component';
import { ThankyouFormComponent } from './thankyou-form/thankyou-form.component';
import { ExistingEmailComponent } from './existing-email/existing-email.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { InactiveLinkComponent } from './inactive-link/inactive-link.component';
import { AlreadyEarnedPointsComponent } from './already-earned-points/already-earned-points.component';



const routes: Routes = [
  { path: 'form', component: FormComponent },
  { path: 'form/:qcCode', component: FormComponent },
  { path: 'thankyou', component: ThankyouFormComponent },
  { path: 'existing', component: ExistingEmailComponent },
  { path: 'already-rewarded', component: AlreadyEarnedPointsComponent },
  { path: 'notFound', component: NotFoundComponent },
  { path: 'inactive', component: InactiveLinkComponent },
  { path: '', redirectTo: '/form', pathMatch: 'full' }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

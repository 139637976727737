import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormService } from '../form.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  myForm: FormGroup;
  submitted: boolean;
  personQCCode: string;
  memberQCCode: string;
  msgResult: string;
  attributeName: string;
  attributeValue: string;
  existingEmailAttributeName: string;
  existingEmailAttributeValue: string;

  constructor(private fb: FormBuilder, private router: Router, private formService: FormService, private route: ActivatedRoute) {
    this.myForm = this.fb.group({ email: ['', [Validators.required, Validators.pattern(/^\w+([.-]?\w+)@\w{2,}([.-]?\w+)(.\w{2,3})+$/)]] });
  }

  ngOnInit() {
    this.submitted = false;
    this.route.paramMap.subscribe(params => {
      this.memberQCCode = params.get('qcCode');
      console.log('Member QC Code is: ', this.memberQCCode);
    });
  }


  onSubmit() {
    this.submitted = true;
    console.log(this.myForm.get('email').errors)
    console.log(this.myForm.value)

    if (this.myForm.valid) {
      this.formService.memberSearch(this.memberQCCode).subscribe(res => {
        if (res.success && res.payload.data.length === 1) {
          if (res.payload.data[0].loyaltyMembershipData.length === 1 && res.payload.data[0].loyaltyMembershipData[0].attributes.length === 1) {
            this.iterateAttributes(res.payload.data[0].loyaltyMembershipData[0].attributes);
            // this.attributeName = res.payload.data[0].loyaltyMembershipData[0].attributes[0].attributeName;
            // this.attributeValue = res.payload.data[0].loyaltyMembershipData[0].attributes[0].attributeValue;
          }

          if (this.attributeName != 'undefined' && this.attributeName === 'EMAIL_UPDATE_FROM_LANDING_PAGE' && this.attributeValue === "true") {
            this.router.navigateByUrl('/already-rewarded');
          } else if (this.existingEmailAttributeName != 'undefined' && this.existingEmailAttributeName === 'ALREADY_EXISTING_EMAIL_FROM_LANDING_PAGE' && this.existingEmailAttributeValue === "true") {
            this.router.navigateByUrl('/inactive');
          } else {
            this.personQCCode = res.payload.data[0]["QCCode"];
            this.formService.updateEmail(this.myForm.value.email, this.personQCCode).subscribe(res => {
              console.log(res.payload)
              if (!res.success) {
                this.formService.markMember(this.personQCCode, this.memberQCCode, 'ALREADY_EXISTING_EMAIL_FROM_LANDING_PAGE', "true").subscribe(res => {
                  console.log(res.payload)
                });
                this.router.navigateByUrl('/existing');
              } else {
                this.formService.markMember(this.personQCCode, this.memberQCCode, 'EMAIL_UPDATE_FROM_LANDING_PAGE', "true").subscribe(res => {
                  console.log(res.payload)
                });
                this.router.navigateByUrl('/thankyou');
              }
            });
          }
        } else {
          this.router.navigateByUrl('/notFound');
        }
      });
    }
  }

  iterateAttributes(attributeList) {
    attributeList.forEach(obj => {
      if ('EMAIL_UPDATE_FROM_LANDING_PAGE' === obj.attributeName) {
        this.attributeName = obj.attributeName
        this.attributeValue = obj.attributeValue;
      }
      if ('ALREADY_EXISTING_EMAIL_FROM_LANDING_PAGE' === obj.attributeName) {
        this.existingEmailAttributeName = obj.attributeName
        this.existingEmailAttributeValue = obj.attributeValue;
      }
    })
  };

}
